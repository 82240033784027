<template>
  <teleport to="body">
    <div
      ref="refUserProfileCard"
      v-if="props.modelValue && loadingFlag"
      class="mik-user-profile-card-container"
      tabindex="0"
      :style="`top: ${props.position.y}px; left: ${props.position.x}px;`">
      <div class="user-info-detail">
        <div class="user-profile-popover">
          <div class="close-btn" @click="close">
            <q-icon name="close" />
          </div>
          <div class="user-profile-info">
            <div class="img-box">
              <mik-profile :src="profileInfo?.profileImageUrl ?? ''" />
            </div>
            <div class="user-profile-info_props">
              <p class="username-first">
                <b>{{ profileInfo?.name }}</b>
              </p>
              <p class="username-last">{{ profileInfo?.deptName }}</p>
            </div>
          </div>
        </div>
        <div class="user-profile-popover_menu">
          <ul class="user-profile-menu-list">
            <!-- 이메일주소 -->
            <li class="user-profile-menu-li">
              <div class="icon-profile-container">
                <q-icon name="email" />
              </div>
              <span class="user-profile-menu-text">
                {{ profileInfo?.emailAddress }}
              </span>
              <span class="user-profile-copy-btn" @click="copyData(profileInfo?.emailAddress)">
                ({{ $t('UserProfileCardClipboardCopyLabel', '복사') }})
              </span>
            </li>
            <li class="user-profile-menu-li">
              <div class="icon-profile-container">
                <q-icon name="account_box" />
              </div>
              <span class="user-profile-menu-text">
                {{ profileInfo?.titleName }}
              </span>
            </li>

            <!-- 휴대폰번호 -->
            <li class="user-profile-menu-li">
              <div class="icon-profile-container">
                <q-icon name="phone_iphone" />
              </div>
              <span class="user-profile-menu-text">
                {{ profileInfo?.mobileTel }}
              </span>
              <span class="user-profile-copy-btn" @click="copyData(profileInfo?.mobileTel)">
                ({{ $t('UserProfileCardClipboardCopyLabel', '복사') }})
              </span>
            </li>

            <!-- 사무실번호 -->
            <li class="user-profile-menu-li">
              <div class="icon-profile-container">
                <q-icon name="phone" />
              </div>
              <span class="user-profile-menu-text">
                {{ profileInfo?.officeTel }}
              </span>
              <span class="user-profile-copy-btn" @click="copyData(profileInfo?.officeTel)">
                ({{ $t('UserProfileCardClipboardCopyLabel', '복사') }})
              </span>
            </li>

            <!-- 1:1 대화 (인증쿠키 관련해서 구현이 어려움) -->
            <!-- <li class="user-profile-menu-li">
        <div class="icon-profile-container">
          <work-planner-icon url="chat" class="icon-profile icon-profile-mail" />
        </div>
        <span class="user-profile-menu-text"> 1:1 채팅 </span>
      </li> -->
          </ul>
        </div>
        <div class="popper__arrow" style="left: 142.5px"></div>
      </div>
    </div>
  </teleport>
</template>
<script setup lang="ts">
import { useToast } from 'mik-workplanner/src/common/toast'
import { onClickOutside } from '@vueuse/core'
import { UserApi, UserProfileModel } from 'mik-common/src/api-autogen/globalmenu'
import { useI18n } from 'vue-i18n'
//import MikProfileImage from '../MikProfileImage.vue'
const { t } = useI18n()
const refUserProfileCard = ref(null)
onClickOutside(refUserProfileCard, (e: MouseEvent) => {
  close(e)
})

const props = defineProps<{
  position: {
    x: number
    y: number
  }
  userId: string
  modelValue: boolean
}>()

const emit = defineEmits<{ (e: 'update:modelValue', value: boolean): void }>()
const toast = useToast()
const userApi = new UserApi()

const profileInfo = ref<UserProfileModel>()
const loadingFlag = ref<boolean>()

const getUserProfileInfo = async () => {
  loadingFlag.value = false
  const response = await userApi.v1UserUserProfileCardGet(props.userId)
  loadingFlag.value = true
  if (response.data.success) {
    profileInfo.value = response.data.data
  }
}

const close = (event: MouseEvent) => {
  emit('update:modelValue', false)
}

const copyData = (value: string | undefined | null) => {
  if (!value) return
  const textArea = document.createElement('textarea')
  textArea.value = value
  document.body.appendChild(textArea)
  textArea.select()
  try {
    document.execCommand('copy')
  } catch (err) {
    // 복사 실패
    toast.error(t('UserProfileCardClipboardCopyFailMessage', '클립보드에 복사 실패하였습니다.'))
  }
  toast.info(t('UserProfileCardClipboardCopySuccessMessage', '클립보드에 복사되었습니다.'))
  document.body.removeChild(textArea)
}

watch(
  () => props.userId,
  () => {
    getUserProfileInfo()
  }
)
</script>
<style lang="scss"></style>
