<template>
  <q-dialog v-model="model">
    <q-card class="pop-container">
      <q-card-section class="pop-header">
        <div class="text-subtitle1">Smart Portal</div>
      </q-card-section>
      <div>
        <q-card-section class="row justify-center">
          <!-- TODO : 이미지 아이콘 변경(공통으로 변경한다) -->
          <q-icon color="red" name="check_circle" size="6.3em" />
        </q-card-section>
        <q-card-section class="row justify-center text-subtitle1">
          {{ props.message }}
        </q-card-section>
        <q-card-actions align="center" class="bg-white text-teal q-mb-md">
          <mik-button
            v-close-popup
            style="width: 95px"
            icon="done"
            :label="props.okText"
            size="md"
            @click.prevent="handleOkClick" />
        </q-card-actions>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
// @ts-nocheck
import MikButton from '../common/MikButton.vue'
export interface Props {
  message?: string
  okText?: string
  okCallback?: any | null
}

const props = withDefaults(defineProps<Props>(), {
  message: '',
  okText: '확인',
  okCallback: null
})

const model: Ref<boolean | undefined> = ref(true)

const handleOkClick = () => {
  if (props.okCallback && typeof props.okCallback === 'function') {
    props.okCallback()
  } else {
    close()
  }
}

const show = () => {
  // this.$refs.dialog.show()
}
const hide = () => {
  // this.$refs.dialog.hide()
}
const onHide = () => {
  // this.$emit('hide')
}

defineExpose({
  show,
  hide,
  onHide
})
</script>

<style scoped lang="scss">
.pop-container {
  width: 480px;
  .pop-header {
    background-color: #272727;
    color: #fff;
    font-size: 1rem !important;
    padding: 14px;
  }
}

.justify-center {
  padding: 9px;
}

.justify-center.text-subtitle1 {
  font-weight: 700;
}
</style>
